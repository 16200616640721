<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <b-row v-for="(detail, index) in formData" :key="index" class="mt-3">
                              <b-col lg="5" md="5" sm="5" xs="5">
                                <ValidationProvider v-if="$store.state.Auth.activeRoleId === 1" name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="detail.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col lg="5" md="5" sm="5" xs="5"></b-col>
                              <b-col lg="5" md="5" sm="5" xs="5">
                                  <ValidationProvider name="Trainer Evaluation Name (En)" vid="trainer_evaluation_name" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="trainer_evaluation_name"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('elearning_config.evaluation_name_en')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                      id="trainer_evaluation_name"
                                      v-model="detail.trainer_evaluation_name"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col lg="5" md="5" sm="5" xs="5">
                                  <ValidationProvider name="Trainer Evaluation Name (Bn)" vid="trainer_evaluation_name_bn" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="trainer_evaluation_name_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('elearning_config.evaluation_name_bn')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                      id="trainer_evaluation_name_bn"
                                      v-model="detail.trainer_evaluation_name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col xl="2" lg="2" sm="2" class="mt-5" v-if="!id">
                                  <b-button v-show="index == formData.length-1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>{{ $t('globalTrans.add') }}</b-button>
                                  <b-button v-show="index || ( !index && formData.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                              </b-col>
                          </b-row>
                          <b-row>
                          <div class="col text-right mt-3">
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                              &nbsp;
                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                          </b-row>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{formData}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationStore, trainerEvaluationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getTrainerEvaluationData()
      this.formData[0] = tmp
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.trainingCategory.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        formData: [
          {
            org_id: 0,
            trainer_evaluation_name: '',
            trainer_evaluation_name_bn: '',
            status: 1
          }
        ]
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
  },
  methods: {
    addItem () {
      const obj = {
            org_id: this.formData.org_id,
            trainer_evaluation_name: '',
            trainer_evaluation_name_bn: '',
            status: 1
      }
        const key1 = parseInt(this.formData.length - 1)
        const item = this.formData[key1]
        let isEmpty = true
        Object.keys(item).map(key => {
            if (item[key] === '' || item[key] === 0) {
                isEmpty = false
            }
        })
        if (isEmpty === true) {
            this.formData.push(obj)
        }
    },
    remove (key) {
      this.formData.splice(key, 1)
    },
    getTrainerEvaluationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainerEvaluationUpdate}/${this.id}`, this.formData[0])
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerEvaluationStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
